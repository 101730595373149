import React, { Component } from "react";
import styled from "styled-components";
import ImageWithLoading from "./ImageWithLoading";
import Theme from "../Theme";

const md = require("markdown-it")({
  html: true,
  linkify: true,
  typographer: true,
});

class BlogDisplay extends Component {
  render() {
    let { blog, extraCss = "" } = this.props;
    return (
      <Wrapper extraCss={extraCss}>
        <div className={"banner"}>
          <Image src={blog.image} />
        </div>
        <h2 className={"title"}>{blog.title}</h2>

        <div className={"abstract"}>{blog.abstract}</div>

        <div className={"sections-container"}>
          {blog.sections.map((section, idx) => {
            switch (section.type) {
              case "title":
                return (
                  <div key={idx} className={"section-title"}>
                    {section.value}
                  </div>
                );
              case "image":
                return (
                  <div key={idx} className={"section-image"}>
                    <ImageWithLoading src={section.value} />
                  </div>
                );
              case "video":
                return (
                  <video
                    key={idx}
                    className={"section-video"}
                    width="320"
                    height="240"
                    controls
                  >
                    <source src={section.value} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                );
              case "content":
              default:
                return (
                  <div
                    key={idx}
                    className="section-content"
                    dangerouslySetInnerHTML={{
                      __html: md.render(section.value),
                    }}
                  />
                );
            }
          })}
        </div>
      </Wrapper>
    );
  }
}

const Image = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  ${props => props.css || ""}
`;

const Wrapper = styled.div`
  padding: 20px;
  font-family: "jf-jinxuan", "Noto Sans TC", sans-serif;
  border-radius: 3px;
  overflow: hidden;
  background-color: #fff;
  max-width: 700px;
  margin: 0 auto;
  & > .title {
    font-size: ${Theme.sizeArtiHead1}px;
    color: ${Theme.colorDark};
    font-weight: 400;
    margin: 15px 0;
  }
  & > .abstract {
    font-style: italic;
    font-size: ${Theme.sizeArtiBody}px;
    font-weight: 300;
    color: ${Theme.colorGrey};
    margin: 15px 0;
  }
  & > .banner {
    height: 400px;
    margin: -20px -80px 20px -80px;
  }
  & .sections-container {
    .section-title {
      font-size: ${Theme.sizeArtiHead2}px;
      color: ${Theme.colorDark};
      font-weight: 400;
      padding-bottom: 2px;
      margin: 15px 0px;
    }
    .section-image {
    }
    .section-content {
      font-size: ${Theme.sizeArtiBody}px;
      color: ${Theme.colorGrey};
      text-align: left;
      margin-bottom: 10px;
      line-height: 1.8em;
      font-weight: 200;
      letter-spacing: 1px;
      & h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 30px 0px;
      }
      & div {
        margin-bottom: 15px;
      }
      & p {
        margin-bottom: 15px;
      }
      & span {
      }
      & a {
      }
      & img {
        max-width: 100%;
        height: auto;
      }
      & pre {
        border-radius: 3px;
        padding: 15px;
        background-color: #f6f8fa;
        line-height: 1.5em;
        font-weight: 200;
        font-size: 14px;
      }
      & code {
      }
      & ul,
      li {
        list-style-position: inside;
      }
      & ol,
      li {
        list-style-position: inside;
      }
    }
  }

  ${props => props.extraCss}
`;

export default BlogDisplay;
