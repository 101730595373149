import React from "react";
import { connect, Provider } from "react-redux";
import { getStore } from "./Store";
import Layout from "./Layout";
import Selectors from "./Selectors";
import ActionCreator from "./ActionCreator";
import { BlogContextProvider } from "../blog/BlogContext";
import * as constants from "./constants";
import "antd/dist/antd.css";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
    console.warn("*** Error Boundary ***", error, info);
  }

  render() {
    let { error } = this.state;

    if (error) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ padding: 20, backgroundColor: "grey" }}>
            <h2 style={{ color: "white" }}>Oops! We have errors!</h2>
            <p style={{ color: "#ddd" }}>Please reload the page</p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayWaitingUi: !!props.tokenInUrl,
      error: null,
    };
  }

  async componentDidMount() {
    let { appActions, tokenInUrl } = this.props;
    if (tokenInUrl) {
      this.setState({ displayWaitingUi: true });
      try {
        await appActions.loginByJwtToken(tokenInUrl);
      } catch (err) {
        this.setState({ error: "Fail to login!" });
      }
      this.setState({ displayWaitingUi: false });
    } else {
      try {
        await appActions.autoLogin();
      } catch (err) {}
    }
  }

  render() {
    const { displayWaitingUi, error } = this.state;

    if (displayWaitingUi) {
      return (
        <Layout key={this.props.lang} {...this.props}>
          <div style={{ padding: 10 }}>
            <h3>請稍候...</h3>
          </div>
        </Layout>
      );
    }

    if (error) {
      return (
        <Layout key={this.props.lang} {...this.props}>
          <div style={{ padding: 10 }}>
            <h3>您的權限有誤, 無法登入</h3>
          </div>
        </Layout>
      );
    }

    return (
      <ErrorBoundary>
        <Layout key={this.props.lang} {...this.props}>
          {this.props.children}
        </Layout>
      </ErrorBoundary>
    );
  }
}

Page = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
    tokenInUrl: Selectors.getQueryParams(ownProps).token,
  }),
  ActionCreator
)(Page);

export function withPage(Comp) {
  class PageWrapper extends React.Component {
    componentWillMount() {
      let { pageContext } = this.props;
      // create redux store
      getStore(pageContext.initialState);
    }

    render() {
      return (
        <Provider store={getStore()}>
          <BlogContextProvider apiHost={constants.serviceBlogApi}>
            <Page {...this.props}>
              <Comp {...this.props}>{this.props.children}</Comp>
            </Page>
          </BlogContextProvider>
        </Provider>
      );
    }
  }

  PageWrapper.displayName = "Page-" + Comp.displayName;
  return PageWrapper;
}
