const Settings = {
    SERVICE_NAME: "rev-blog",
    VERSION: "0.0.2",
    ENV: "prod",
    PORT: 3000,
    DATA_SOURCE: "MONGO_URL_LIVE",
    SERVICE_STORAGE_HOST: "https://storage.revtel-api.com/v1",
    SERVICE_AUTH_HOST: "https://auth.revtel-api.com/v1",
    webUrl: "http://localhost:3000",
    apiUrl: "http://localhost:8000",
    mediaHost: "https://dvkvars7ubgms.cloudfront.net",
    s3Host: "https://s3-ap-northeast-1.amazonaws.com/stg.hecafe.com.tw",
    debug: false,
    serviceBlogApi: "https://article.revtel-api.com",
};

module.exports = Settings;

