import React, { Component } from "react";
import styled from "styled-components";

class CustomTextareaField extends Component {
  render() {
    const { field, value, values, onChange, extraAttrs = {} } = this.props;

    return (
      <div style={{ marginBottom: 5 }}>
        <label style={{ display: "inline-block", marginBottom: 10 }}>
          {field.label || field.name}
        </label>
        <RevTextarea
          type={field.type}
          name={field.name}
          value={value || ""}
          onChange={e => onChange(e, e.target.value)}
          {...extraAttrs}
        />
      </div>
    );
  }
}

const RevTextarea = styled.textarea`
  padding: 5px 8px;
  border: 1px solid grey;

  max-width: 100%;
  width: 100%;
  min-height: 100px;

  resize: none;

  &:focus {
    outline: none;
  }
`;

export default CustomTextareaField;
