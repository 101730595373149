const ApiProxyFactory = require("./ApiProxy");
const constants = require("./constants");

const ApiProxy = new ApiProxyFactory({ apiUrl: constants.apiUrl });

module.exports = {
  getToken: () => {
    return ApiProxy.token;
  },

  setToken: token => {
    ApiProxy.setToken(token);
  },

  echo: async msg => {
    return msg;
  },

  login: async ({ account, password }, clientConfig) => {
    let resp, decoded;

    resp = await ApiProxy.post({
      path: `${clientConfig.loginApi}`,
      withHost: true,
      secure: false,
      data: {
        username: account,
        password,
      },
    });

    resp = await ApiProxy.get({
      path: `${constants.SERVICE_AUTH_HOST}/jwt/encode?token=${resp.token}&client_id=${clientConfig.authClientId}`,
      withHost: true,
      secure: false,
    });

    decoded = await ApiProxy.get({
      path: `${constants.SERVICE_AUTH_HOST}/jwt/decode?token=${resp.token}`,
      withHost: true,
      secure: false,
    });

    return {
      token: resp.token,
      ...decoded,
    };
  },

  loginByJwtToken: async token => {
    let resp, decoded;

    decoded = await ApiProxy.get({
      path: `${constants.SERVICE_AUTH_HOST}/jwt/decode?token=${token}`,
      withHost: true,
      secure: false,
    });

    return {
      token,
      ...decoded,
    };
  },
};
