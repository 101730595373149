import React, { Component } from "react";
import ArticleApiFactory from "./ApiArticle";

const defaultContext = {
  articleApi: null,
};

const BlogContext = React.createContext(defaultContext);
const Actions = {};

class BlogContextProvider extends Component {
  constructor(props) {
    super(props);
    const { apiHost } = props;
    this.state = {
      articleApi: ArticleApiFactory({ apiHost }),
    };
  }

  render() {
    return (
      <BlogContext.Provider value={this.state}>
        {this.props.children}
      </BlogContext.Provider>
    );
  }
}

class BlogContextConsumer extends Component {
  render() {
    return (
      <BlogContext.Consumer>
        {state => {
          return this.props.children(state);
        }}
      </BlogContext.Consumer>
    );
  }
}

// use case for need context in comp life cycle
const withBlogContext = Comp => {
  class WrappedComp extends Component {
    render() {
      let { children } = this.props;
      return (
        <BlogContextConsumer>
          {value => {
            let contextReady = false;
            if (value.articleApi) {
              contextReady = true;
            }
            return (
              <Comp {...this.props} value={value} contextReady={contextReady}>
                {children}
              </Comp>
            );
          }}
        </BlogContextConsumer>
      );
    }
  }

  return WrappedComp;
};

export { BlogContextProvider, BlogContextConsumer, Actions, withBlogContext };
