import React, { Component } from "react";
import { Dropdown, Button, Menu, Input } from "antd";
import { DownOutlined } from "@ant-design/icons";
import FileUpload from "./FileUpload";

class CustomSectionField extends Component {
  render() {
    let { field } = this.props;
    if (field.name === "type") {
      return <TypeSelectField {...this.props} />;
    } else if (field.name === "value") {
      return <ValueField {...this.props} />;
    }
    return <div>UNEXPECT FIELD: {field.name}</div>;
  }
}

class TypeSelectField extends Component {
  render() {
    let { allSectionTypes, activeSectionType } = this.props;
    const menu = (
      <Menu onClick={this._handleMenuClick}>
        {allSectionTypes.map((t, idx) => (
          <Menu.Item key={t.name}>{t.name}</Menu.Item>
        ))}
      </Menu>
    );

    return (
      <div style={{ marginBottom: 5 }}>
        <label style={{ marginRight: 5 }}>格式</label>
        <Dropdown overlay={menu}>
          <Button>
            {activeSectionType.name} <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    );
  }

  _handleMenuClick = e => {
    let { onChange } = this.props;
    onChange(null, e.key);
  };
}

class ValueField extends Component {
  render() {
    let {
      field,
      value,
      activeSectionType,
      onChange,
      fileUploadToken,
    } = this.props;
    const _getInputComp = () => {
      if (activeSectionType.format === "textarea") {
        return (
          <Input.TextArea
            value={value}
            placeholder="input content here..."
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={e => onChange(e, e.target.value)}
          />
        );
      } else if (activeSectionType.format === "file") {
        return (
          <FileUpload
            token={fileUploadToken}
            accept={activeSectionType.accept}
            defaultValue={value}
            onUploadSuccess={url => onChange(null, url)}
            onUploadError={err => console.log(err)}
          />
        );
      } else {
        return (
          // default format: string
          <Input
            placeholder="input text here..."
            value={value}
            onChange={e => onChange(e, e.target.value)}
          />
        );
      }
    };
    const inputComp = _getInputComp();

    return <div style={{ marginBottom: 5 }}>{inputComp}</div>;
  }
}

export default CustomSectionField;
