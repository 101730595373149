import styled from "styled-components";
import * as MaterialIcon from "styled-icons/material";
import * as BoxIconsLogos from "styled-icons/boxicons-logos";
import * as AntIcon from "@ant-design/icons";

function IconWrapper(IconComp) {
  let Icon = styled(IconComp)`
    color: ${props => props.color || "black"};
    ${props => props.css}
  `;
  return Icon;
}

const Apps = IconWrapper(MaterialIcon.Apps);

const Star = IconWrapper(MaterialIcon.Star);

const StarBorder = IconWrapper(MaterialIcon.StarBorder);

const Stars = IconWrapper(MaterialIcon.Stars);

const People = IconWrapper(MaterialIcon.People);

const Person = IconWrapper(MaterialIcon.Person);

const Close = IconWrapper(MaterialIcon.Close);

const PlayCircleOutline = IconWrapper(MaterialIcon.PlayCircleOutline);

const Favorite = IconWrapper(MaterialIcon.Favorite);

const Share = IconWrapper(MaterialIcon.Share);

const ArrowUpward = IconWrapper(MaterialIcon.ArrowUpward);

const ArrowDownward = IconWrapper(MaterialIcon.ArrowDownward);

const ArrowBack = IconWrapper(MaterialIcon.ArrowBack);

const ArrowForward = IconWrapper(MaterialIcon.ArrowForward);

const KeyboardArrowLeft = IconWrapper(MaterialIcon.KeyboardArrowLeft);

const KeyboardArrowUp = IconWrapper(MaterialIcon.KeyboardArrowUp);

const KeyboardArrowDown = IconWrapper(MaterialIcon.KeyboardArrowDown);

const KeyboardArrowRight = IconWrapper(MaterialIcon.KeyboardArrowRight);

const Check = IconWrapper(MaterialIcon.Check);

const Edit = IconWrapper(MaterialIcon.Edit);

const CheckBox = IconWrapper(MaterialIcon.CheckBox);

const AddCircle = IconWrapper(MaterialIcon.AddCircle);

const AddCircleOutline = IconWrapper(MaterialIcon.AddCircleOutline);

const RemoveCircle = IconWrapper(MaterialIcon.RemoveCircle);

const RemoveCircleOutline = IconWrapper(MaterialIcon.RemoveCircleOutline);

const Menu = IconWrapper(MaterialIcon.Menu);

const Search = IconWrapper(MaterialIcon.Search);

const AccountCircle = IconWrapper(MaterialIcon.AccountCircle);

const Email = IconWrapper(MaterialIcon.Email);

const Lock = IconWrapper(MaterialIcon.Lock);

const Bookmark = IconWrapper(MaterialIcon.Bookmark);

const UnfoldMore = IconWrapper(MaterialIcon.UnfoldMore);

const Delete = IconWrapper(MaterialIcon.Delete);

const Reply = IconWrapper(MaterialIcon.Reply);

const Language = IconWrapper(MaterialIcon.Language);

const WbSunny = IconWrapper(MaterialIcon.WbSunny);

const CloudDone = IconWrapper(MaterialIcon.CloudDone);

const SupervisorAccount = IconWrapper(MaterialIcon.SupervisorAccount);

const Loyalty = IconWrapper(MaterialIcon.Loyalty);

const ShoppingCart = IconWrapper(MaterialIcon.ShoppingCart);

const ExpandMore = IconWrapper(MaterialIcon.ExpandMore);

const Autorenew = IconWrapper(MaterialIcon.Autorenew);

const Add = IconWrapper(MaterialIcon.Add);

const Home = IconWrapper(MaterialIcon.Home);

const RadioButtonUnchecked = IconWrapper(MaterialIcon.RadioButtonUnchecked);

const RadioButtonChecked = IconWrapper(MaterialIcon.RadioButtonChecked);

const List = IconWrapper(MaterialIcon.List);

const Location = IconWrapper(MaterialIcon.LocationOn);

const Facebook = IconWrapper(BoxIconsLogos.Facebook);

const Google = IconWrapper(BoxIconsLogos.Google);

const Code = IconWrapper(MaterialIcon.Code);

const RemoveRedEye = IconWrapper(MaterialIcon.RemoveRedEye);

const UserOutlined = IconWrapper(AntIcon.UserOutlined);

const LockOutlined = IconWrapper(AntIcon.LockOutlined);

const UnorderedListOutlined = IconWrapper(AntIcon.UnorderedListOutlined);

const HomeOutlined = IconWrapper(AntIcon.HomeOutlined);

const LogoutOutlined = IconWrapper(AntIcon.LogoutOutlined);

const BellOutlined = IconWrapper(AntIcon.BellOutlined);

const SearchOutlined = IconWrapper(AntIcon.SearchOutlined);

const CodeOutlined = AntIcon.CodeOutlined;

const EyeOutlined = AntIcon.EyeOutlined;

const SaveOutlined = AntIcon.SaveOutlined;

const FileCopy = IconWrapper(MaterialIcon.FileCopy);

const OpenInNew = IconWrapper(MaterialIcon.OpenInNew);

export {
  Apps,
  Star,
  StarBorder,
  Stars,
  People,
  Person,
  Close,
  PlayCircleOutline,
  Favorite,
  Share,
  ArrowUpward,
  ArrowDownward,
  ArrowBack,
  ArrowForward,
  KeyboardArrowLeft,
  KeyboardArrowUp,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Check,
  Edit,
  CheckBox,
  AddCircle,
  AddCircleOutline,
  RemoveCircle,
  RemoveCircleOutline,
  Menu,
  Search,
  AccountCircle,
  Email,
  Lock,
  Bookmark,
  UnfoldMore,
  Delete,
  Reply,
  Language,
  WbSunny,
  CloudDone,
  SupervisorAccount,
  Loyalty,
  ShoppingCart,
  ExpandMore,
  Autorenew,
  Add,
  Home,
  RadioButtonUnchecked,
  RadioButtonChecked,
  List,
  Location,
  Facebook,
  Google,
  Code,
  RemoveRedEye,
  UserOutlined,
  LockOutlined,
  UnorderedListOutlined,
  HomeOutlined,
  LogoutOutlined,
  SearchOutlined,
  BellOutlined,
  CodeOutlined,
  EyeOutlined,
  SaveOutlined,
  FileCopy,
  OpenInNew,
};
