import React, { Component } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import constants from "../constants";

class FileUpload extends Component {
  constructor(props) {
    super(props);
    let defaultFileList = props.defaultValue
      ? [
          {
            uid: "-1",
            name: "current image",
            status: "done",
            url: props.defaultValue,
          },
        ]
      : [];
    this.state = {
      fileList: defaultFileList,
    };
  }

  render() {
    let { token, accept = "", defaultValue, onUploadSuccess } = this.props;
    const props = {
      action: `${constants.SERVICE_STORAGE_HOST}/file/upload?token=${token}`,
      onChange: this._handleChange,
      multiple: true,
      accept,
      showUploadList: {
        showDownloadIcon: false,
        showRemoveIcon: false,
      },
    };

    let { fileList } = this.state;
    return (
      <Upload {...props} fileList={fileList}>
        <Button>
          <UploadOutlined /> Upload
        </Button>
      </Upload>
    );
  }

  _handleChange = info => {
    let { onUploadSuccess, onUploadError } = this.props;
    if (info.file.status === "done") {
      onUploadSuccess(info.file.response.result);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show one recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.result;
      }
      return file;
    });

    this.setState({ fileList });
  };
}

export default FileUpload;
