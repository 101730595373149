import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import styled from "styled-components";
import Article, { SECTIONS_MODE } from "../../blog/Components/Article";
import CustomInputField from "./CustomInputField";
import CustomTextareaField from "./CustomTextareaField";
import BlogDisplay from "./Blog.display";
import * as Ant from "antd";
import Theme from "../Theme";
import * as Icon from "./Icon";
import FileUpload from "./FileUpload";
import CustomSectionField from "./CustomSectionField";
import ServiceClients from "../ServiceClients";
import { ControlOutlined, BuildOutlined } from "@ant-design/icons";
import CopyToClipboard from "./CopyToClipboard";

const UISTATE = {
  edit: "0",
  preview: "1",
  deleted: "2",
};

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: props.isEditor ? UISTATE.edit : UISTATE.preview,
    };
  }

  render() {
    let { user, appActions, articleId, onBack, isEditor = false } = this.props;

    let { uiState } = this.state;

    if (uiState === UISTATE.deleted) {
      return <h3>該文章已被刪除</h3>;
    }

    return (
      <Wrapper>
        <TabContainer
          isEditor={isEditor}
          onTabChange={tab => this.setState({ uiState: tab })}
        >
          <Article
            id={articleId}
            token={user && user.token}
            fileUploadToken={user.token}
            extraSectionTypes={
              ServiceClients[user.app_name]?.extraSectionTypes || []
            }
            CustomSectionFieldComp={CustomSectionField}
            spinnerComp={
              <div
                style={{
                  paddingTop: 50,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Ant.Spin />
              </div>
            }
          >
            {({
              article,
              isInitializing,
              renderSection,
              sectionMode,
              actions: {
                setSectionMode,
                moveSectionDown,
                moveSectionUp,
                createSection,
                removeSection,
                editSectionValueByIdx,
                editArticleTitle,
                editArticleLabel,
                editArticleBannerImg,
                editArticleFlag,
                editArticleAbstract,
                saveArticle,
                resetArticle,
                deleteArticle,
              },
            }) => {
              if (uiState === UISTATE.preview) {
                return (
                  <div className={"content-wrapper"} style={{ padding: 0 }}>
                    <BlogDisplay
                      blog={{
                        ...article,
                        sections: article.sections.filter((item, idx, arr) =>
                          sectionMode === SECTIONS_MODE.DEFAULT
                            ? idx === 0
                            : true
                        ),
                      }}
                    />
                  </div>
                );
              }
              return (
                <>
                  <Ant.Space
                    style={{
                      paddingBottom: 10,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Ant.Space>
                      ID: {article.id}
                      <CopyToClipboard text={article.id} onCopy={() => 0} />
                      <HoverBlock
                        style={{ width: 30, height: 30 }}
                        onClick={() => window.open(`/viewer/?id=${article.id}`)}
                      >
                        <Icon.OpenInNew size={24} color={"#c3c3c3"} />
                      </HoverBlock>
                    </Ant.Space>
                    <Ant.Space>
                      <Ant.Button
                        style={{ borderRadius: Theme.radiusButton }}
                        onClick={resetArticle}
                      >
                        復原
                      </Ant.Button>
                      <Ant.Button
                        type="primary"
                        style={{ borderRadius: Theme.radiusButton }}
                        onClick={async () => {
                          const key = "save-article";
                          Ant.message.loading({ content: "儲存中...", key });
                          await saveArticle();
                          Ant.message.success({
                            content: "成功儲存！",
                            key,
                            duration: 1,
                          });
                        }}
                      >
                        <Icon.SaveOutlined />
                        儲存{" "}
                      </Ant.Button>
                      <Ant.Button
                        type="danger"
                        style={{ borderRadius: Theme.radiusButton }}
                        onClick={async () => {
                          if (!confirm("確定刪除嗎?")) {
                            return;
                          }

                          try {
                            const key = "delete-article";
                            Ant.message.loading({ content: "刪除中...", key });
                            await deleteArticle();
                            Ant.message.success({
                              content: "成功刪除文章",
                              key,
                              duration: 1,
                            });
                            this.setState({ uiState: UISTATE.deleted });
                            onBack();
                          } catch (ex) {
                            alert("Oops! API Error!");
                          }
                        }}
                      >
                        刪除文章{" "}
                      </Ant.Button>
                    </Ant.Space>
                  </Ant.Space>

                  <Ant.Row gutter={12}>
                    <Ant.Col span={12}>
                      <Ant.Space
                        direction="vertical"
                        size="middle"
                        style={{ width: "100%" }}
                      >
                        <div className={"content-wrapper"}>
                          <div className="title">標題管理</div>
                          <div className="divider" />

                          <CustomInputField
                            onChange={e => editArticleTitle(e.target.value)}
                            value={article.title}
                            field={{
                              name: "a-title",
                              type: "text",
                              label: "標題",
                            }}
                          />

                          <CustomInputField
                            onChange={e => editArticleLabel(e.target.value)}
                            value={article.label}
                            field={{
                              name: "a-label",
                              type: "text",
                              label: "標籤",
                            }}
                          />

                          <div
                            style={{
                              marginBottom: 5,
                            }}
                          >
                            <label style={{ marginRight: 10 }}>{"主圖"}</label>
                            <FileUpload
                              token={user.token}
                              accept="image/*"
                              defaultValue={article.image}
                              onUploadSuccess={url => editArticleBannerImg(url)}
                              onUploadError={err => console.log(err)}
                            />
                          </div>

                          <CustomTextareaField
                            onChange={e => editArticleAbstract(e.target.value)}
                            value={article.abstract}
                            field={{
                              name: "a-abstract",
                              type: "text",
                              label: "摘要",
                            }}
                          />
                        </div>
                        <div className={"content-wrapper"}>
                          <div
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <div className="title">內文管理 </div>
                            <Ant.Button
                              icon={
                                sectionMode === SECTIONS_MODE.DEFAULT ? (
                                  <ControlOutlined />
                                ) : (
                                  <BuildOutlined />
                                )
                              }
                              onClick={() =>
                                setSectionMode(
                                  sectionMode === SECTIONS_MODE.DEFAULT
                                    ? SECTIONS_MODE.MULTIPLE
                                    : SECTIONS_MODE.DEFAULT
                                )
                              }
                            />
                          </div>
                          <div className="divider" />
                          {/* if section mode is default, we view article content as a whole(use sections[0]) ; if not, break down section */}
                          {article.sections
                            .filter((item, idx, arr) =>
                              sectionMode === SECTIONS_MODE.DEFAULT
                                ? idx === 0
                                : true
                            )
                            .map((itemValues, itemIdx) => (
                              <>
                                {itemIdx !== 0 && <div className="divider" />}
                                <div key={itemIdx}>
                                  {sectionMode === SECTIONS_MODE.DEFAULT ? (
                                    <SectionsWrapper>
                                      <FileUpload
                                        style={{ marginBottom: 5 }}
                                        token={user.token}
                                        accept="image/*"
                                        defaultValue={""}
                                        onUploadSuccess={url => {
                                          let value = {
                                            ...itemValues,
                                            value: `${itemValues.value}\r\n<img src="${url}" width="100%" alt="content-image"/>`,
                                          };
                                          editSectionValueByIdx(itemIdx, value);
                                        }}
                                        onUploadError={err => console.log(err)}
                                      />
                                      <Ant.Input.TextArea
                                        style={{ marginTop: 5 }}
                                        rows={10}
                                        value={itemValues.value}
                                        onChange={e => {
                                          let value = {
                                            ...itemValues,
                                            value: e.target.value,
                                          };
                                          editSectionValueByIdx(itemIdx, value);
                                        }}
                                      />
                                    </SectionsWrapper>
                                  ) : (
                                    <>
                                      <SectionsWrapper>
                                        {renderSection({ itemValues, itemIdx })}
                                      </SectionsWrapper>
                                      <Ant.Space>
                                        <Ant.Button
                                          style={{
                                            borderRadius: Theme.radiusButton,
                                          }}
                                          onClick={() => moveSectionUp(itemIdx)}
                                        >
                                          上移
                                        </Ant.Button>
                                        <Ant.Button
                                          style={{
                                            borderRadius: Theme.radiusButton,
                                          }}
                                          onClick={() =>
                                            moveSectionDown(itemIdx)
                                          }
                                        >
                                          下移
                                        </Ant.Button>
                                        <Ant.Button
                                          style={{
                                            borderRadius: Theme.radiusButton,
                                          }}
                                          onClick={() => removeSection(itemIdx)}
                                          type="danger"
                                        >
                                          刪除
                                        </Ant.Button>
                                      </Ant.Space>
                                    </>
                                  )}
                                </div>
                              </>
                            ))}
                        </div>

                        {(sectionMode !== SECTIONS_MODE.DEFAULT ||
                          article.sections.length === 0) && (
                          <Ant.Button type="primary" onClick={createSection}>
                            <Icon.Add
                              color="white"
                              css="font-size: 16px;"
                              size="16"
                            />
                            加入新段落
                          </Ant.Button>
                        )}
                      </Ant.Space>
                    </Ant.Col>

                    <Ant.Col span={12}>
                      <div className={"content-wrapper"} style={{ padding: 0 }}>
                        <BlogDisplay
                          blog={{
                            ...article,
                            sections: article.sections.filter(
                              (item, idx, arr) =>
                                sectionMode === SECTIONS_MODE.DEFAULT
                                  ? idx === 0
                                  : true
                            ),
                          }}
                        />
                      </div>
                    </Ant.Col>
                  </Ant.Row>
                </>
              );
            }}
          </Article>
        </TabContainer>
      </Wrapper>
    );
  }
}

const TabContainer = props => {
  let { isEditor, children, onTabChange } = props;
  return isEditor ? (
    <Ant.Tabs
      defaultActiveKey={UISTATE.edit}
      onChange={activeKey => onTabChange(activeKey)}
    >
      <Ant.Tabs.TabPane
        key={UISTATE.edit}
        tab={
          <span>
            <Icon.CodeOutlined /> Edit{" "}
          </span>
        }
      >
        {children}
      </Ant.Tabs.TabPane>
      <Ant.Tabs.TabPane
        key={UISTATE.preview}
        tab={
          <span>
            <Icon.EyeOutlined /> Preview{" "}
          </span>
        }
      >
        {children}
      </Ant.Tabs.TabPane>
    </Ant.Tabs>
  ) : (
    children
  );
};

const HoverBlock = styled.div`
  &:hover {
    background-color: rgba(226, 226, 226, 0.63);
  }
  cursor: pointer;
  padding: 3px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SectionsWrapper = styled.div`
  & .section-field-container.type {
    margin-bottom: 10px;
    label {
      margin-right: 10px;
    }
    select {
    }
  }
  & .section-field-file-upload-wrapper {
    & > div {
      display: flex;
      align-items: center;
    }
    .section-field-file-upload-input {
    }
    .file-upload-btn {
      padding: 5px 16px;
      background-color: #babdc4;
      border-radius: 10px;
      cursor: pointer;
      border: none;
      align-self: flex-end;
      font-size: 12px;
    }
    .section-field-file-upload-preview-text {
    }
  }
  & .section-field-container.value {
    margin-bottom: 10px;
    label {
      margin-right: 10px;
    }
    input {
      padding: 5px 8px;
      border: 1px solid grey;
      &:focus {
        outline: none;
      }
    }
    textarea {
      width: 100%;
      height: 150px;
      resize: none;
      padding: 5px 8px;
      border: 1px solid grey;
      margin-top: 5px;
      &:focus {
        outline: none;
      }
    }
  }
`;

const Wrapper = styled.div`
  & .content-wrapper {
    padding: 15px 30px;
    background-color: white;
    & > .title {
      font-size: 16px;
      font-weight: 500;
    }
    & > .divider {
      height: 1px;
      background-color: #eee;
      margin: 15px -30px;
    }
  }
`;

export default connect(
  (state, ownProps) => ({
    user: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(Blog);
