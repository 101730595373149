import React from "react";

class FileUploadField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      uploading: false,
    };
  }

  render() {
    let { type = "file", extraInputAttr } = this.props;
    let { uploading } = this.state;

    return this.props.children({
      inputAttrs: {
        type,
        ...extraInputAttr,
        onChange: this._onInputChange,
      },
      uploading,
      onSubmit: this._submit,
    });
  }

  _onInputChange = e => {
    console.log(e.target.files[0]);
    this.setState({ file: e.target.files[0] });
  };

  _submit = async () => {
    let { file } = this.state;
    let { token, onUploadStart, onUploadSuccess, onUploadError } = this.props;

    try {
      this.setState({ uploading: true });
      await onUploadStart();
      let formData = new FormData();

      formData.append("file", file);

      // TODO: allow stg / production config
      let resp = await fetch(
        `https://storage-stg.revtel-api.com/v1/file/upload?token=${token}`,
        {
          method: "POST",
          body: formData,
        }
      );

      this.setState({ uploading: false });
      await onUploadSuccess(await resp.json());
      return resp;
    } catch (err) {
      this.setState({ uploading: false });
      await onUploadError(err);
      return;
    }
  };
}

FileUploadField.defaultProps = {
  token: null,
  onUploadSuccess: async resp => 0,
  onUploadError: async err => 0,
  onUploadStart: async () => 0,
  extraInputAttr: {},
};

export default FileUploadField;
