module.exports = {
  "revtel-official-website": {
    display: "忻旅官網",
    logoImage: require("./images/revtel-logo.png"),
    loginApi: "https://revtel-web-api.revtel2.com/api/user/login/",
    authClientId: "kvzx6mx1u4",

    // section type // we consider to deprecate `extraSectionTypes` in future since it seems not used very often.
    extraSectionTypes: [],
  },

  sensationsprint: {
    display: "理想印製",
    logoImage: require("./images/sensation-logo.png"),
    loginApi: "https://api.sensationsprint.com/api/user/login/",
    authClientId: "82rfoi3ac1",

    // section type // we consider to deprecate `extraSectionTypes` in future since it seems not used very often.
    extraSectionTypes: [],
  },
};
