import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import { SupportLangs } from "../StringTable";

class Footer extends Component {
  render() {
    let { extraStyle = "", pathname, appActions } = this.props;

    return (
      <Wrapper extraStyle={extraStyle}>
        <div className="copyright">
          Copyright © 2020 Revteltech Limited. All rights reserved.
        </div>
        <div onClick={appActions.logout}>Temp logout button</div>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background-color: #f8f8f8;
  width: 100vw;
  padding: 40px 10px 80px 10px;
  color: #505050;
  display: flex;
  flex-direction: column;

  & > .copyright {
    font-weight: 500;
    text-align: center;
    font-size: 12px;
  }
`;

export default connect(null, ActionCreator)(Footer);
