import React, { Component } from "react";
import styled from "styled-components";

class CustomInputField extends Component {
  render() {
    const { field, value, values, onChange, extraAttrs = {} } = this.props;

    return (
      <div
        style={{
          marginBottom: 5,
        }}
      >
        <label style={{ marginRight: 10 }}>{field.label || field.name}</label>
        <RevInput
          type={field.type}
          name={field.name}
          value={value || ""}
          onChange={e => onChange(e, e.target.value)}
          {...extraAttrs}
        />
      </div>
    );
  }
}

const RevInput = styled.input`
  padding: 5px 8px;
  border: 1px solid grey;
  &:focus {
    outline: none;
  }
`;

export default CustomInputField;
