import React, { Component } from "react";
import styled from "styled-components";
import CmsMultiItemForm from "rev-cms-core/dist/cms-widgets/MultiItemForm";
import FileUploadInput from "./FileUploadInput";
import { withBlogContext } from "../BlogContext";

export const PREDEFINED_SECTION_TYPE = [
  { name: "title", format: "string" },
  { name: "content", format: "textarea" },
  { name: "image", format: "file", accept: "image/*" },
  { name: "video", format: "file", accept: "video/*" },
  // example of type: { name: "video", format: "file", accept: "video/*", Comp: function }
];

export const DEFAULT_SECTION_MODEL = {
  name: "Section",
  fields: [
    { name: "type", type: "options", label: "Type", defaultValue: "content" },
    { name: "value", type: "string", label: "Value", defaultValue: "" },
  ],
};

/*
  article: { title (string), image (string), [auto] created (string), [auto] updated (string) }
*/

class SectionFieldComp extends React.Component {
  state = {
    uploadedFileUrl: "",
  };
  render() {
    let {
      field,
      value,
      values,
      onChange,
      extraAttrs = {},
      extraSectionTypes = [],
      fileUploadToken = "",
      CustomSectionFieldComp = null,
    } = this.props;
    const { uploadedFileUrl } = this.state;

    extraSectionTypes = extraSectionTypes.filter(
      type => type.name && type.format
    );
    const ALL_SECTION_TYPE = [...PREDEFINED_SECTION_TYPE, ...extraSectionTypes];
    const activeSectionType = ALL_SECTION_TYPE.find(
      item => item.name === values.type
    );

    if (CustomSectionFieldComp) {
      return (
        <CustomSectionFieldComp
          {...{
            ...this.props,
            allSectionTypes: ALL_SECTION_TYPE,
            activeSectionType,
          }}
        />
      );
    }

    // we still provide default implementation for section input
    if (field.name === "type") {
      return (
        <div className={"section-field-container type"}>
          <label>{field.label}</label>
          <select
            value={value}
            onChange={e => {
              this.setState({ uploadedFileUrl: "" });
              onChange(e, e.target.value);
            }}
          >
            {ALL_SECTION_TYPE.map(type => (
              <option key={type.name} value={type.name}>
                {type.name}
              </option>
            ))}
          </select>

          {activeSectionType && activeSectionType.format === "file" && (
            <FileUploadInput
              token={fileUploadToken}
              onUploadStart={() => onChange(null, `${value}::UPLOADING`)}
              onUploadSuccess={async resp => {
                onChange(null, `${value}::${resp.result}`);
                this.setState({ uploadedFileUrl: resp.result });
              }}
            >
              {({ onSubmit, inputAttrs, uploading }) => (
                <div className={"section-field-file-upload-wrapper"}>
                  <div style={{ display: "flex" }}>
                    <input
                      className={"section-field-file-upload-input"}
                      {...inputAttrs}
                      accept={activeSectionType.accept || ""}
                    />
                    <div className={"file-upload-btn"} onClick={onSubmit}>
                      Upload
                    </div>
                  </div>
                  <div
                    className={"section-field-file-upload-preview-text"}
                    style={{ color: "grey", fontSize: 12 }}
                  >
                    {uploadedFileUrl}
                  </div>
                </div>
              )}
            </FileUploadInput>
          )}
        </div>
      );
    }

    if (field.name === "value" && activeSectionType.Comp) {
      const Comp = activeSectionType.Comp;
      return <Comp {...this.props} activeSectionType={activeSectionType} />;
    }

    return (
      <div
        className={`section-field-container value format-${activeSectionType.format}`}
      >
        <label>{field.label}</label>
        {activeSectionType.format === "textarea" ? (
          <textarea
            type={field.type}
            name={field.name}
            value={value}
            onChange={e => onChange(e, e.target.value)}
            disabled={value === "UPLOADING"}
            {...extraAttrs}
          />
        ) : (
          <input
            type={field.type}
            name={field.name}
            value={value}
            onChange={e => onChange(e, e.target.value)}
            disabled={value === "UPLOADING"}
            {...extraAttrs}
          />
        )}
      </div>
    );
  }
}

export const SECTIONS_MODE = {
  DEFAULT: "DEFAULT",
  MULTIPLE: "MULTIPLE",
};
class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: null,
      loading: true,
      sectionMode: SECTIONS_MODE.DEFAULT,
    };
  }

  async componentDidMount() {
    let {
      value: { articleApi },
      contextReady,
    } = this.props;
    if (contextReady) {
      await this._fetchArticle();
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      value: { articleApi },
    } = this.props;
    if (articleApi && !prevProps.value.articleApi) {
      await this._fetchArticle();
    }
  }

  render() {
    let {
      id,
      children,
      token,
      extraSectionTypes,
      CustomSectionFieldComp = null,
      contextReady,
      spinnerComp = null, // jsx
      fileUploadToken = "",
    } = this.props;
    let { article, loading, sectionMode } = this.state;

    if (!id) {
      return null;
    }

    if (loading) {
      return spinnerComp ? spinnerComp : <div>Loading...</div>;
    }

    if (!article) {
      return <div>Article Not Found</div>;
    }

    return (
      <Wrapper>
        <CmsMultiItemForm
          FieldComp={withExtraPropsComp(SectionFieldComp, {
            extraSectionTypes,
            fileUploadToken,
            CustomSectionFieldComp,
          })}
          model={DEFAULT_SECTION_MODEL}
          instances={article.sections}
          onChange={({
            e,
            field,
            value,
            values,
            setState, // this setState refer to ALL state of Multiform
            setItemValues,
          }) => {
            if (field.name === "type" && value.indexOf("::") > -1) {
              // a workaround to detect uploading status
              // type: 'image::http://file-upload-url', value: 'http://file-upload-url'
              // type: 'image::UPLOADING', value: 'UPLOADING'
              let [type, _value] = value.split("::");
              setItemValues({ ...values, type, value: _value });
              return true;
            }
            return null;
          }}
        >
          {({
            valuesList,
            renderFields,
            createItem,
            removeItem,
            setValueList,
          }) =>
            spinnerComp && (!contextReady || !article) ? (
              <div style={{ display: "flex", padding: 10 }}>{spinnerComp}</div>
            ) : (
              <div>
                {children({
                  isInitializing: !contextReady,
                  article: { ...article, sections: valuesList },
                  renderSection: renderFields,
                  sectionMode,
                  actions: {
                    setSectionMode: mode =>
                      this.setState({ sectionMode: mode }),
                    moveSectionDown: this._moveSectionDown.bind(
                      null,
                      valuesList,
                      setValueList
                    ),
                    moveSectionUp: this._moveSectionUp.bind(
                      null,
                      valuesList,
                      setValueList
                    ),
                    createSection: createItem,
                    removeSection: removeItem,
                    editSectionValueByIdx: this._editSectionValueByIdx.bind(
                      null,
                      valuesList,
                      setValueList
                    ), // ... idx, value)
                    saveArticle: this._updateArticle.bind(null, valuesList),
                    resetArticle: this._fetchArticle,
                    deleteArticle: this._deleteArticle,
                    editArticleTitle: title =>
                      this.setState({ article: { ...article, title } }),
                    editArticleLabel: label =>
                      this.setState({ article: { ...article, label } }),
                    editArticleBannerImg: image =>
                      this.setState({ article: { ...article, image } }),
                    editArticleFlag: flag =>
                      this.setState({ article: { ...article, flag } }),
                    editArticleAbstract: abstract =>
                      this.setState({ article: { ...article, abstract } }),
                  },
                })}
              </div>
            )
          }
        </CmsMultiItemForm>
      </Wrapper>
    );
  }

  _fetchArticle = async () => {
    let {
      id,
      contextReady,
      value: { articleApi },
    } = this.props;
    if (!contextReady) {
      return;
    }

    this.setState({ loading: true });
    try {
      let article = await articleApi.getArticleById(id);
      let initialSectionMode = SECTIONS_MODE.DEFAULT;
      if (article.sections.length > 1) {
        initialSectionMode = SECTIONS_MODE.MULTIPLE;
      }
      this.setState({ article, sectionMode: initialSectionMode });
    } catch (ex) {
      console.warn("Article fetch ex", ex);
    }
    this.setState({ loading: false });
  };

  _moveSectionUp = (sections, updateSectionsFn, idx) => {
    let _sections = [...sections];
    if (idx === 0) {
      return;
    }
    _sections.splice(idx - 1, 2, sections[idx], sections[idx - 1]);
    updateSectionsFn(_sections);
  };

  _moveSectionDown = (sections, updateSectionsFn, idx) => {
    let _sections = [...sections];
    if (idx === sections.length - 1) {
      return;
    }
    _sections.splice(idx, 2, sections[idx + 1], sections[idx]);
    updateSectionsFn(_sections);
  };

  _editSectionValueByIdx = (sections, updateSectionsFn, idx, value) => {
    let _sections = [...sections];
    _sections[idx] = value;
    updateSectionsFn(_sections);
  };

  _deleteArticle = async () => {
    let { article } = this.state;
    let {
      token,
      contextReady,
      value: { articleApi },
    } = this.props;
    if (!contextReady) {
      return;
    }
    let resp = await articleApi.deleteArticle(article.id, token);
    return resp;
  };

  _updateArticle = async (sectionsData, data) => {
    let _article = { ...this.state.article, sections: sectionsData };
    let article = data ? { ..._article, ...data } : { ..._article };
    let {
      token,
      contextReady,
      value: { articleApi },
    } = this.props;
    if (!contextReady) {
      return;
    }
    article = {
      ...article,
    };
    let resp = await articleApi.updateArticle(article.id, article, token);
    return resp;
  };
}

const withExtraPropsComp = (Comp, extraProps) => {
  class _Comp extends React.Component {
    render() {
      return <Comp {...this.props} {...extraProps} />;
    }
  }
  return _Comp;
};

Article.defaultProps = {
  id: null,
  token: null,
  extraSectionTypes: [],
  contextReady: false,
};

const Wrapper = styled.div``;

export default withBlogContext(Article);
