import React, { Component } from "react";
import styled from "styled-components";
import * as Ant from "antd";

class ImageWithLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    let { src, css = "" } = this.props;
    let { loading } = this.state;
    return (
      <Wrapper css={css} src={src} loading={loading}>
        {loading && (
          <div className="spinner">
            <Ant.Spin />
          </div>
        )}
        <img src={src} onLoad={() => this.setState({ loading: false })} />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  & .spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & img {
    max-width: 100%;
    height: auto;
  }
`;

export default ImageWithLoading;
