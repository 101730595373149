const ArticleApiFactory = ({ apiHost }) => {
  if (!apiHost) {
    throw "MISSING REQUIRED PARAMS: Article Api Facotry needs apiHost";
  }

  return {
    createArticle: async token => {
      const resp = await fetch(`${apiHost}/articles/create?token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }

      return await resp.json();
    },

    countArticles: async ({ project, ...extras }) => {
      const resp = await fetch(`${apiHost}/articles/count`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          find: {
            project,
            ...extras,
          },
        }),
      });

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }
      return await resp.json();
    },

    getArticles: async ({
      project,
      label,
      offset = 0,
      limit = 60,
      sort = "",
    }) => {
      let url = `${apiHost}/articles?project=${project}&offset=${offset}&limit=${limit}`;

      if (label) {
        url += `&label=${label}`;
      }
      if (sort) {
        url += `&sort=${sort}`;
      }

      const resp = await fetch(url);

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }
      return await resp.json();
    },

    getArticleById: async id => {
      const resp = await fetch(`${apiHost}/article/${id}`);

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }

      return await resp.json();
    },

    updateArticle: async (id, data, token) => {
      const resp = await fetch(`${apiHost}/article/${id}?token=${token}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }

      return await resp.json();
    },

    deleteArticle: async (id, token) => {
      const resp = await fetch(`${apiHost}/article/${id}?token=${token}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }

      return;
    },
  };
};

export default ArticleApiFactory;
