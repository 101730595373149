import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "./Icon";

class CopyToClipboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }
  render() {
    let {
      text = "example text to copy",
      copyBtnComp = null,
      onCopy = () => 0,
    } = this.props;
    let { copied } = this.state;
    return (
      <Wrapper>
        <div>
          <textarea
            style={{ opacity: 0, position: "absolute", width: 0, height: 0 }}
            ref={textarea => (this._copyTextRef = textarea)}
            value={text}
            onChange={() => 0}
          />
        </div>
        <div className="action-btn-container" onClick={this._copyToClipboard}>
          {copyBtnComp || <Icon.FileCopy size={20} color={"#c3c3c3"} />}
          <div className="tooltip">{copied ? "copied!" : "copy"}</div>
        </div>
      </Wrapper>
    );
  }

  _onCopied = () => {
    this.setState({ copied: true });
    this._dissmissCopiedStateCountDown = setTimeout(() => {
      this._dissmissCopiedStateCountDown &&
        clearTimeout(this._dissmissCopiedStateCountDown);
      this._dissmissCopiedStateCountDown = null;
      this.setState({ copied: false });
    }, 1000);
  };

  _copyToClipboard = async () => {
    let { text = "example text to copy", onCopy = () => 0 } = this.props;
    function selectElementText(element) {
      if (document.selection) {
        var range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        var range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
    }
    var element = document.createElement("DIV");
    element.textContent = text;
    document.body.appendChild(element);
    selectElementText(element);
    document.execCommand("copy");
    element.remove();
    this._onCopied();
    onCopy();
  };
}

const Wrapper = styled.div`
  --tooltip-bg-color: rgba(0, 0, 0, 0.5);
  --tooltip-height: 7px;
  --tooltip-height-inverse: -7px;
  & .action-btn-container {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
  }

  & .tooltip {
    position: absolute;
    pointer-events: none;
    background-color: var(--tooltip-bg-color);
    padding: 6px 9px;
    top: 10px;
    transform: translateY(-100%) translateY(var(--tooltip-height-inverse));
    font-size: 14px;
    border-radius: 30px;
    color: white;
    opacity: 0;
    transition: opacity 0.3s, top 0.3s;
  }
  & .tooltip:after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    border: none;
    border-top: var(--tooltip-height) solid var(--tooltip-bg-color);
    border-left: var(--tooltip-height) solid transparent;
    border-right: var(--tooltip-height) solid transparent;
    bottom: var(--tooltip-height-inverse);
    left: 50%;
    transform: translateX(var(--tooltip-height-inverse));
  }

  & .action-btn-container:hover .tooltip {
    opacity: 1;
    top: -3px;
  }
`;

export default CopyToClipboard;
