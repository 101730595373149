import { navigate } from "gatsby";
import Actions from "./Reducers/Actions";
import Catchify from "./Utils/Catchify";
import Api from "./Api";
const ServiceClients = require("./ServiceClients");

export default dispatch => ({
  navActions: {
    push: Catchify(async (url, param) => {
      if (!param) {
        navigate(`/${url}`);
      } else {
        navigate(`/${url}`, param);
      }
    }),
  },

  appActions: {
    setLang: Catchify(async lang => {
      dispatch({ type: Actions.SET_LANG, payload: lang });
    }),

    echo: async () => {
      return await Api.echo("hello");
    },

    logout: () => {
      try {
        window.localStorage.removeItem("token");
        Api.setToken(null);
        dispatch({ type: Actions.LOGOUT, payload: null });
      } catch (err) {
        throw err;
      }
    },

    autoLogin: async () => {
      try {
        let token = window.localStorage.getItem("token");

        if (!token) {
          throw "local data not found";
        }

        let profile = await Api.loginByJwtToken(token);

        Api.setToken(profile.token);
        dispatch({ type: Actions.LOGIN, payload: profile });
      } catch (err) {
        Api.setToken(null);
        window.localStorage.removeItem("token");
        throw err;
      }
    },

    login: async ({ account, password }, appName) => {
      try {
        let profile = await Api.login(
          { account, password },
          ServiceClients[appName]
        );

        if (profile.app_name !== appName) {
          throw new Error(
            `Inconsistent project: ${appName} vs ${profile.app_name}`
          );
        }

        Api.setToken(profile.token);
        dispatch({ type: Actions.LOGIN, payload: profile });

        try {
          window.localStorage.setItem("token", profile.token);
        } catch (err) {
          console.warn("cannot set localStorage! ");
        }

        return profile;
      } catch (err) {
        Api.setToken(null);
        dispatch({ type: Actions.LOGOUT, payload: null });
        throw err;
      }
    },

    loginByJwtToken: async token => {
      try {
        let profile = await Api.loginByJwtToken(token);

        Api.setToken(profile.token);
        dispatch({ type: Actions.LOGIN, payload: profile });

        try {
          window.localStorage.setItem("token", profile.token);
        } catch (err) {
          console.warn("cannot set localStorage! ");
        }

        return profile;
      } catch (err) {
        Api.setToken(null);
        dispatch({ type: Actions.LOGOUT, payload: null });
        throw err;
      }
    },
  },
});
