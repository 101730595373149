import Actions from "./Actions";

const initialState = {
  data: null,
};

export default function reducer(state = initialState, action) {
  let { orders } = state;
  switch (action.type) {
    case Actions.LOGIN:
      return {
        ...state,
        data: action.payload,
      };
    case Actions.AUTO_LOGIN:
      return {
        ...state,
        data: action.payload,
      };
    case Actions.LOGOUT:
      return { ...initialState };
    case Actions.UPDATE_PROFILE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export function getLoginUser(state) {
  return state.data;
}
